/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const me = /* GraphQL */ `
  query Me {
    me {
      Username
      UserAttributes {
        Name
        Value
        __typename
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
        __typename
      }
      PreferredMfaSetting
      UserMFASettingList
      UserProvidedDescription
      __typename
    }
  }
`;
