import "@aws-amplify/ui/dist/style.css";
import Amplify from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";

import React, { useEffect, useState } from "react";
import "./App.css";
import awsconfig from "./aws-exports";
import logo from "./logo.svg";

import GraphQLAPI, {
  GRAPHQL_AUTH_MODE,
  GraphQLResult,
  graphqlOperation,
} from "@aws-amplify/api";
import { me } from "./graphql/queries";
import * as APIt from "./api";
import userEvent from "@testing-library/user-event";

type User = Omit<Exclude<APIt.User, null>, "__typename">;

Amplify.configure(awsconfig);

function App() {
  const [loadedDescription, setLoadedDescription] = useState<string | undefined>(undefined)
  const [meUser, setMeUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    const fetchMe = async () => {
      try {
        const response = (await GraphQLAPI.graphql({
          query: me,
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })) as { data: APIt.MeQuery };
        console.log(response);
        if (response.data.me) {
          setMeUser(response.data.me);
          if (response.data.me.UserProvidedDescription) {
            setLoadedDescription(response.data.me.UserProvidedDescription);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (!meUser) {
      fetchMe();
    }
  });

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <table>
          <tr><td>Username</td><td>{meUser?.Username}</td></tr>
          <tr><td>Description</td><td>{loadedDescription ?? "Nothing loaded yet"}</td></tr>
        </table>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default withAuthenticator(App, true);
